<template>
    <div id="page">
        <vue-headful title="Free Chat Rooms, 5000+ Chat Rooms to Chat for Free  - Paltalk"/>
        <div id="main-wrapper" class="clearfix">
            <div id="main-content">
                <search-bar-groups
                        :openRoomsCount="openRoomsCount"
                        :keywordParent="keyword"
                        :errorMsg="errorMsg"
                        @search="search"
                        @updateKeyword="updateKeyword"/>
                <top-ad/>
                <div id="content">
                    <div class="myanchor" id="myanchor"></div>
                    <div class="section">
                        <div class="sticky-search" id="sticky-search">
                            <div class="panel filter-container">
                                <div class="panel-title" id="panel-title" @click="onScreenResize()">
                                    <a href="javascript:void(0)" class="down-arrow"></a>
                                    <h5><i class="filter-icon"></i> Popular Free Chat Rooms/Filters</h5>
                                </div>
                                <div class="panel-content" :class="{'active': isMobileView}">
                                    <fieldset>
                                        <div class="label">Language:</div>
                                        <v-select
                                                class="form-field notranslate"
                                                v-model="language"
                                                :options="languageOpts"
                                                @input="filterSearch">
                                            <template slot="option" slot-scope="option">
                                                {{ option.label }}
                                            </template>
                                        </v-select>
                                    </fieldset>
                                    <fieldset>
                                        <div class="label">Category:</div>
                                        <v-select
                                                class="form-field notranslate"
                                                v-model="category"
                                                :options="categoryOpts"
                                                @input="loadSubCategories">
                                            <template slot="option" slot-scope="option">
                                                {{ option.label }}
                                            </template>
                                        </v-select>
                                    </fieldset>
                                    <fieldset>
                                        <div class="label">Sub Category:</div>
                                        <v-select
                                                class="form-field notranslate"
                                                v-model="subCategory"
                                                :options="subCategoryOpts"
                                                @input="filterSearch"
                                                :disabled="isCategoryDisable">
                                            <template slot="option" slot-scope="option">
                                                {{ option.label }}
                                            </template>
                                        </v-select>
                                    </fieldset>
                                    <fieldset>
                                        <div class="label">Rating:</div>
                                        <v-select
                                                class="form-field notranslate"
                                                v-model="rating"
                                                :options="ratingOpts"
                                                @input="filterSearch">
                                            <template slot="option" slot-scope="option">
                                                {{ option.label }}
                                            </template>
                                        </v-select>
                                    </fieldset>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                        <div class="main-content">
                            <section id="search-filter">
                                <left-ad/>
                            </section>
                            <section id="search-result">
                                <div class="sort-by" v-show="showSortBy">
                                    <div class="label">Sort By:</div>
                                    <v-select
                                            class="form-field notranslate"
                                            v-model="sortBy"
                                            :options="sortByOpts"
                                            @input="filterSearch">
                                        <template slot="option" slot-scope="option">
                                            {{ option.label }}
                                        </template>
                                    </v-select>
                                </div>
                                <div class="clearfix"></div>
                                <div class="content">
                                    <ul class="masonry-container" id="top-members-ul">
                                        <group-profile-card
                                                v-for="group in groups"
                                                :key="group.gid"
                                                :group="group"
                                                :imgPrefix="imgUrl"
                                                :noIcon="noIcon"/>
                                    </ul>
                                </div>
                                <infinite-loading @infinite="infiniteHandler" ref="infiniteLoading" spinner="circles">
                                    <div slot="no-more" v-show="page > 1" class="no-more">
                                        <p>End of Content</p>
                                    </div>
                                    <div slot="no-results" class="no-results">
                                        <div class="sad"><i class="icon-smily"></i></div>
                                        <p>Your search did not yield any results</p>
                                    </div>
                                </infinite-loading>
                            </section>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VueCookies from 'vue-cookies';
    import vSelect from 'vue-select';
    import axios from 'axios';
    import changeCase from 'change-case';
    import InfiniteLoading from 'vue-infinite-loading';
    import slug from 'speakingurl';
    import SearchBarGroups from '../components/common/SearchBarGroups';
    import TopAd from '../components/googleads/Top';
    import LeftAd from '../components/googleads/Left';
    import GroupProfileCard from '../components/widget/GroupProfileCard';
    import PaltalkConnectApi from '../services/PaltalkConnectApi';
    import LiveSearchApi from '../services/LiveSearchApi';

    Vue.use(VueCookies);

    const sortOptsAll = [
        { label: 'Select', value: '' },
        { label: 'Name - A to Z', value: '1' },
        { label: 'Name - Z to A', value: '2' },
        { label: 'Rating - Low to High', value: '3' },
        { label: 'Rating - High to Low', value: '4' },
        { label: 'Followers - Low to High', value: '5' },
        { label: 'Followers - High to Low', value: '6' }
    ];

    export default {
        name: 'searchGroups',
        data: () => ({
            isMobileView: false,
            openRoomsCount: '0000',
            pages: 0,
            page: 0,
            imgUrl: '',
            noIcon: {},
            t: 0,
            showSortBy: true,
            errorMsg: '',
            groups: [],
            keyword: '',
            language: { label: 'Select', value: '' },
            category: { label: 'Select', value: '' },
            subCategory: { label: 'Select', value: '' },
            rating: { label: 'Select', value: '' },
            sortBy: { label: 'Select', value: '' },
            languageOpts: [],
            categoryOpts: [],
            subCategoryOpts: [],
            ratingOpts: [
                { label: 'Select', value: '' },
                { label: 'G', value: 'G' },
                { label: 'R', value: 'R' }
            ],
            sortByOpts: sortOptsAll,
            dirty: false,
            isCategoryDisable:true,
        }),
        components: {
            GroupProfileCard,
            LeftAd,
            TopAd,
            SearchBarGroups,
            vSelect,
            InfiniteLoading
        },
        watch: {
            "$route.params": function () {
                const query = this.$route.query || {};
                this.loadCategories(query);
            }
        },
        beforeCreate() {

        },
        created() {
            this.groups.length = 0;
            this.page = 0;
            // this.getOpenRoomsCount();

            const query = this.$route.query || {};
            this.loadCategories(query);
            this.loadLanguages(query);

            // read query params
            const params = (this.$route.query) || {};
            Object.keys(params).forEach(key => {
                const value = params[key];
                if (value !== '') {
                    if (key === 'keyword') {
                        this.keyword = value;
                    } else if (key === 'rating') {
                        this.ratingOpts.forEach(r => {
                            if (r.value.toUpperCase() === value.toUpperCase()) {
                                this.rating = r;
                            }
                        });
                    } else if (key === 'sort') {
                        this.sortByOpts.forEach(s => {
                            if (parseInt(s.value, 10) === parseInt(value, 10)) {
                                this.sortBy = s;
                            }
                        });
                    }
                }
            });

            window.addEventListener('scroll', ()=>{
                this.scrollPage()
            });
        },
        methods: {
            setOrResetErrorMsg() {
                this.errorMsg = '';
               if (this.keyword !== '' && this.keyword.length < 3) {
                    this.errorMsg = 'Search keyword should not be lesser than 3 characters';
                    return true;
                }
                return false;
            },
            getOpenRoomsCount() {
                PaltalkConnectApi.getOnlineStats()
                    .then(resp => {
                        this.openRoomsCount = resp.data['total_groups'].toString();
                    });
            },
            loadCategories(query) {
              let found = false;
              LiveSearchApi.getAllCategories()
                  .then(resp => {
                    this.categoryOpts = [];
                    const p = this.$route.params || {};
                    const seoCategory = (p || {}).seoCategory || '';
                    const seoCategoryCode = this.findCategoryBySeoName(seoCategory);
                    const q = (query || {}).category || seoCategoryCode;

                    // fill category dropdown
                    this.categoryOpts.push({ label: 'Select', value: '' });
                    const t = (resp.data) || [];
                    t.forEach(row => {
                      if (row.value !== 2150 && row.value !== 9999) {
                        this.categoryOpts.push(row);
                      }
                    });

                    // set selected option
                    if (q !== '') {
                      this.categoryOpts.forEach(c => {
                        if (parseInt(c.value, 10) === parseInt(q, 10)) {
                          this.category = c;
                          found = true;
                          return;
                        }
                      });
                      if (found) {
                        this.loadSubCategories();
                      }
                    }
                  });
            },
            loadSubCategories() {
                const query = this.$route.query || {};
                const category = this.category.value;
                console.log("loadSubCategories", category);
                this.subCategory = { label: 'Select', value: '' };
                this.subCategoryOpts = [{ label: 'Select', value: '' }];
                if (category !== '') {
                    this.isCategoryDisable = false;
                    LiveSearchApi.getAllSubCategories(category)
                        .then(resp => {
                            this.subCategoryOpts.push(...resp.data);
                            const p = this.$route.params || {};
                            const seoSubCategory = (p || {}).seoSubCategory || '';
                            const seoSubCategoryCode = this.findSubCategoryBySeoName(seoSubCategory);
                            const q = (query || {}).subcategory || seoSubCategoryCode;
                            if (q !== '') {
                                this.subCategoryOpts.forEach(s => {
                                    if (parseInt(s.value, 10) === parseInt(q, 10)) {
                                        this.subCategory = s;
                                        return;
                                    }
                                });
                            }
                        })
                        .catch(error => {
                            console.debug(error);
                        });
                }
                else{
                    this.isCategoryDisable = true;
                }
            },
            loadLanguages(query) {
              LiveSearchApi.getAllLanguages()
                  .then(resp => {
                    this.languageOpts.push({ label: 'Select', value: '' });
                    this.languageOpts.push(...resp.data);
                    const q = parseInt((query || {}).language || '0');
                    if (q > 0) {
                      this.languageOpts.forEach(l => {
                        if (parseInt(l.value, 10) === q) {
                          this.language = l;
                          return;
                        }
                      });
                    }
                  });
            },
            updateKeyword(keyword) {
                this.keyword = keyword;
                this.errorMsg = '';
            },
            search(keyword) {
                if (this.setOrResetErrorMsg()) {
                    return;
                }
                this.page = 0;
                this.pages = 0;
                this.keyword = keyword;
                this.groups = [];
                this.$nextTick(() => {
                    this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                });
            },
            filterGroups() {
                if (this.setOrResetErrorMsg()) {
                    return;
                }
                this.page = 0;
                this.pages = 0;
                this.groups = [];
                this.$nextTick(() => {
                    this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                });
            },
            toggleFilter() {
                this.isMobileView = !this.isMobileView;
            },
            filterSearch() {
                this.timeoutSearch();
            },
            timeoutSearch() {
                clearTimeout(this.t);
                this.t = setTimeout(this.filterGroups, 500);
            },
            clearFilters() {
                this.keyword = '';
                this.category = { label: 'Select', value: '' };
                this.subCategory = { label: 'Select', value: '' };
                this.language = { label: 'Select', value: '' };
                this.rating = { label: 'Select', value: '' };
                this.sortBy = { label: 'Select', value: '' };
                this.page = 0;
                this.pages = 0;
                this.$nextTick(() => {
                    this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                });
            },
            infiniteHandler($state) {
                if (this.setOrResetErrorMsg()) {
                    return;
                }

                // dirty flag added here to reset the url params after page load
                let p = {};
                let q = {};

                if (this.$route.name === 'searchCategories' || this.$route.name === 'searchSubCategories') {
                    p = this.$route.params || {};
                }

                if (this.$route.name === 'searchGroups' ) {
                    q = !this.dirty && this.$route.query || {}
                }

                this.dirty = !this.dirty;

                const seoCategory = (p || {}).seoCategory || '';
                const seoSubCategory = (p || {}).seoSubCategory || '';
                const seoCategoryCode = this.findCategoryBySeoName(seoCategory);
                const seoSubCategoryCode = this.findSubCategoryBySeoName(seoSubCategory);

                const category = (q || {}).category || seoCategoryCode;
                const language = (q || {}).language || '';
                const rating = (q || {}).rating || '';
                const sort = (q || {}).sort || '';
                const subcategory = (q || {}).subcategory || seoSubCategoryCode;

                let filters = {
                    category: this.category.value || category,
                    subcategory: this.subCategory.value || subcategory,
                    language: this.language.value || language,
                    rating: this.rating.value || rating.toUpperCase(),
                    sort: this.sortBy.value || sort,
                    keyword: (this.keyword) || ''
                };

                const user = this.$cookies.get('username');
                if (user && user.trim().length > 0) {
                    filters.nick = user;
                }

                if (this.$route.name !== 'searchCategories' && this.$route.name !== 'searchSubCategories') {
                    this.$router.replace({name: 'searchGroups', query: filters});
                }

                this.page += 1;
                this.pages += 1;
                filters.page = this.page;
                LiveSearchApi.searchAllGroups(filters)
                    .then(resp => {
                        const response = (resp.data) || {};
                        if (Object.keys(response).length > 0) {
                            // this.resetSortOpts();
                            this.groups.push(...response.hits.groups);
                            this.imgUrl = response.hits.imgPrefix;
                            this.noIcon = response.hits.noIcon;
                            $state.loaded();
                            if (this.groups.length < 25) {
                                $state.complete();
                            } else if (this.pages === 10) {
                                $state.complete();
                            }
                        } else {
                            $state.complete();
                        }
                    })
                    .catch(e => {
                        if (axios.isCancel(e)) {
                            return;
                        }
                        $state.complete();
                    });
            },
            findCategoryBySeoName(seoName) {
                let code = '';
                if (seoName !== '') {
                    this.categoryOpts.forEach(c => {
                        if (slug(c.label).toString() === changeCase.lowerCase(seoName)) {
                            code = c.value;
                            return;
                        }
                    });
                }
                return code;
            },
            findSubCategoryBySeoName(seoName) {
                let code = '';
                if (seoName !== '') {
                    this.subCategoryOpts.forEach(c => {
                        if (slug(c.label) === changeCase.lowerCase(seoName)) {
                            code = c.value;
                            return;
                        }
                    });
                }
                return code;
            },
            seoCategoryName(code){
                let name = '';
                this.categoryOpts.forEach(c => {
                    if (c.value === code) {
                        name = slug(c.label);
                        return;
                    }
                });
                return name;
            },
            seoSubCategoryName(code){
                let name = '';
                this.subCategoryOpts.forEach(s => {
                    if (s.value === code) {
                        name = slug(s.label);
                        return;
                    }
                });
                return name;
            },
            resetSortOpts() {
                this.showSortBy = false;
                this.sortByOpts = sortOptsAll;

                if (this.keyword !== '' || this.category.value !== '' ||
                    this.language.value !== '' || this.rating.value !== '') {
                    this.showSortBy = true;
                }

                if (this.keyword !== '') {
                    if (this.rating.label === 'Select') {
                        this.sortByOpts = [
                            { label: 'Select', value: ''},
                            { label: 'Rating - Low to High', value: '3' },
                            { label: 'Rating - High to Low', value: '4' },
                            { label: 'Followers - Low to High', value: '5' },
                            { label: 'Followers - High to Low', value: '6' }
                        ];
                    } else {
                        this.sortByOpts = [
                            { label: 'Select', value: '' },
                            { label: 'Followers - Low to High', value: '5' },
                            { label: 'Followers - High to Low', value: '6' }
                        ];
                    }
                } else if (this.rating.label !== 'Select') {
                    this.sortByOpts = [
                        { label: 'Select', value: '' },
                        { label: 'Name - A to Z', value: '1' },
                        { label: 'Name - Z to A', value: '2' },
                        { label: 'Followers - Low to High', value: '5' },
                        { label: 'Followers - High to Low', value: '6' }
                    ];
                }
            },
            scrollPage() {
                let scrolledValue = window.scrollY;
                let myanchorPos =  document.getElementById('myanchor').offsetTop;
                let elementOffset;
                if(window.innerWidth > 1025) {
                    elementOffset = myanchorPos - document.getElementById("header").offsetHeight;
                    if(scrolledValue > elementOffset) {
                        document.body.classList.add("sticky");
                        document.getElementById("sticky-search").style.top = document.getElementById("header").offsetHeight+'px'

                    }else {
                        document.body.classList.remove("sticky");
                        document.getElementById("sticky-search").style.top = 0 +'px'
                    }
                } else {
                    elementOffset = myanchorPos;
                    if(scrolledValue > elementOffset) {
                        document.body.classList.add("sticky");
                        document.getElementById("sticky-search").style.top = 0 +'px'
                    } else {
                        document.body.classList.remove('sticky');
                        document.getElementById("sticky-search").style.top = 0 +'px'
                    }
                }
            },
            onScreenResize() {
                if(window.innerWidth > 992) {
                    return;
                }else {
                    if(document.getElementById('panel-title').classList.contains('active')) {
                        document.getElementById('panel-title').classList.remove('active')
                    } else {
                        document.getElementById('panel-title').classList.add('active')
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .filter-icon {
        display: inline-block;
        vertical-align: middle;
        background-color: transparent;
        background-image: url('https://www.palassets.com/sprites/filter_icon.png');
        background-repeat: no-repeat;
        background-position: 0 0;
        width: 12px;
        height: 11px;
    }

    #content {
        padding: 30px 0 50px 0;
        margin: 0;
        float: none;
        min-width: 1px;

        .section {
            width: 100%;
            margin: 0 auto;
            padding: 0 31px;
            max-width: 1312px;
            box-sizing: border-box;

            .page-title {
                width: 100%;
                display: inline-block;
                vertical-align: middle;
                margin-bottom: 20px;
                position: relative;

                h4 {
                    font-size: 20px;
                    font-weight: 300;
                    color: #494949;
                }

                .ip-address {
                    position: absolute;
                    right: 0;
                    top: 5px;
                    color: #f1f1f1;
                }
            }
        }

        .panel {
            background: #fff;

            .title {
                h4 {
                    font-size: 20px;
                    font-weight: 300;
                    color: #494949;

                    span {
                        color: #999;
                        margin-left: 10px;
                    }
                }
            }

            .footer {
                text-align: center;
                padding: 9px 0 13px 0;

                .btn-viewmore, .btn-loadmore {
                    display: inline-block;
                    vertical-align: middle;
                    color: #94A0A8;
                    background: #fff;
                    font-size: 14px;
                    line-height: 18px;
                    padding: 5px 15px;
                    border-radius: 5px;
                    border: solid 1px #EDEDED;
                    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
                }
            }
        }
    }

    #search-filter {
        width: 340px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 25px;
        float: left;
    }

    #search-result {
        width: 100%;
        max-width: 885px;
        display: inline-block;
        vertical-align: middle;
        float: left;

        .sort-by {
            float: right;
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 6px;
            position: relative;
            top: -5px;
            z-index: 1;

            .label {
                display: inline-block;
                vertical-align: middle;
                font-size: 14px;
                font-weight: 500;
                color: #4a4a4a;
                margin: 0 5px 0 0;
            }

            .form-field {
                display: inline-block;
                vertical-align: middle;
                width: 210px;
                margin: 0;
                position: relative;
            }
        }

        .content {
            width: 100%;
            margin: 0 0 25px;
            ul {
                display: grid;
                grid-template-columns: repeat(auto-fill, 160px);
                column-gap: 21px;
                row-gap: 21px;
                li {
                    &.mobile-ad {
                      width: 100%;
                      max-width: 100%;
                      min-height: 50px;
                      text-align: center;
                      margin-right: auto;
                      background: transparent;
                      display: flex;
                      justify-content: center;
                        grid-column: 1 / 3;
                    }
                }
            }
        }

        .footer {
            clear: both;
            padding-top: 25px;
            border-top: solid 1px #c8c8c8;
            text-align: center;

            .btn-seemore {
                display: inline-block;
                padding: 6px 20px;
                border-radius: 19px;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #7F8A92;
                border: solid 1px #c8c8c8;

                &:hover {
                    background: #71c5ff;
                    border-color: #71c5ff;
                    color: #fff;
                }

                &:active {
                    background: #51b0f2;
                    border-color: #51b0f2;
                    color: #fff;
                }
            }
        }

        .no-results {
            padding-top: 100px;
            text-align: center;

            .sad {
                margin-bottom: 20px;

                .icon-smily {
                    display: inline-block;
                    background: transparent url("https://www.palassets.com/peopleweb/miniprofile/images/sprite-miniprofile.svg?v=1707") no-repeat 0 -33px;
                    width: 51px;
                    height: 51px;
                }
            }

            p {
                font-size: 18px;
                color: #999;
                font-weight: 400;
                line-height: 1.3;
            }
        }

        .no-more {
            text-align: center;
            position: relative;
            line-height: 18px;

            p {
                display: inline-block;
                font-size: 13px;
                font-weight: 400;
                color: #999;
                background: #f2f2f2;
                padding: 1px 10px;
                position: relative;
                z-index: 1;
            }

            &:before {
                content: '';
                display: inline-block;
                height: 1px;
                width: 80%;
                position: absolute;
                left: 10%;
                top: 50%;
                background: #ccc;
            }
        }
    }

    .sticky-search {
        width: 100%;
        display: block;
        margin-bottom: 20px;
        position: relative;
        .filter-container {
            display: block;
            width: 100%;
            background: #fff;
            padding: 0;
            .panel-title {
                padding: 15px 0;
                margin: 0 20px;
                border-bottom: solid 1px #e3e3e3;
                h5 {
                    font-size: 18px;
                    font-weight: 300;
                    color: #4a4a4a;
                    display: inline-block;
                    vertical-align: middle;
                    padding-right: 20px;
                    i {
                        margin-right: 5px;
                        position: relative;
                        top: 4px;
                        display: block;
                        float: left;
                        height: 30px;
                    }
                }
                .down-arrow {
                    display: none;
                    float: right;
                    position: relative;
                    top: 1px;
                    &.active {
                        &::before {
                            transform: rotate(315deg);
                        }
                    }
                }
            }

            .panel-content {
                padding: 13px 15px 13px;
                border-top: none;
                margin: 0;
                border-bottom: none;
                box-sizing: border-box;
                fieldset {
                    margin: 0;
                    width: 25%;
                    float: left;
                    padding: 0 1%;
                    box-sizing: border-box;
                    .label {
                        font-size: 14px;
                        color: #858585;
                        line-height: 36px;
                        font-weight: 300;
                        display: inline-block;
                        vertical-align: middle;
                        width: 100px;
                    }

                    .form-field {
                        display: inline-block;
                        vertical-align: middle;
                        width: 100%;
                        min-height: 37px;
                        position: relative;
                    }
                }
            }
        }
    }

    .sticky {
        .sticky-search {
            position: fixed;
            z-index: 99;
            left: 0;
            transition: width .2s ease-in-out;
            border-top: solid 1px #e3e3e3;
            background: #fff;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
            .filter-container {
                margin: 0 auto;
                padding: 0 12px;
                max-width: 1312px;
                box-sizing: border-box;
                display: block;
            }
        }
    }

    @media all and (min-width: 1026px) {
        .sticky-search {
            .filter-container {
                .panel-content {
                    fieldset {
                        &:nth-child(2) {
                            .label {
                                position: relative;
                                top: 0;
                            }
                        }
                        &:nth-child(3) {
                            .label {
                                position: relative;
                                top: -1px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media all and (min-width: 768px) {
        #search-result {
            position: relative;

            .sort-by {
                /*position: absolute;
                top: -45px;
                right: 0;*/
            }
        }
        #content {
          .main-content {
            #search-result {
              .content {
                ul {
                  li {
                    &.mobile-ad {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
    }

    @media all and (min-width: 993px) and (max-width: 1280px) {
        .sticky-search {
            .filter-container {
                .panel-content {
                    fieldset {
                        margin: 0;
                        padding: 0 7px;
                        width: 25%;
                        float: left;
                    }
                }
            }
        }
    }

    @media all and (min-width: 768px) and (max-width: 1350px) {
        #content {
            .main-content {
                display: table;

                #search-filter {
                    display: table-cell;
                    vertical-align: top;
                    padding-right: 25px;
                    float: none;
                    width: 340px;
                    min-width: 340px;
                }

                #search-result {
                    display: table-cell;
                    vertical-align: top;
                    float: none;
                }
            }
        }
    }

    @media all and (max-width: 1025px) {
      .sticky-search {
        .filter-container {
          .panel-content {
            fieldset {
              .label {
                width: 100%;
                margin-bottom: 5px;
              }
              .form-field {
                width: 100%;
                position: relative;
              }
            }
          }
        }
      }
    }

    @media all and (max-width: 1024px) {
      #content {
        .section {
          padding: 0 15px;
        }

        .main-content {
          #search-result {
            .content {
              width: calc(100% + 15px);
              margin-right: -15px;
            }
          }
        }
      }
    }

    @media all and (max-width: 992px) {
        .sticky-search {
            .filter-container {
                .panel-title {
                    &:not(.active) {
                        border-bottom: none;
                    }

                    .down-arrow {
                        display: block;
                        float: right;
                        position: relative;
                        top: 9px;
                        width: 0;
                        height: 0;
                        border-left: 7px solid transparent;
                        border-right: 7px solid transparent;
                        border-top: 7px solid #333;
                    }

                    & + .panel-content {
                        max-height: 0px;
                        overflow: hidden;
                        transition: max-height .5s cubic-bezier(0, 1, 0, 1);
                        padding: 0 1%;

                        fieldset {
                            width: 25%;
                            min-width: 25%;
                            max-width: 25%;
                            box-sizing: border-box;
                        }
                    }

                    &.active {
                        .down-arrow {
                            border-left: 7px solid transparent;
                            border-right: 7px solid transparent;
                            border-bottom: 7px solid black;
                            border-top: none;
                        }
                        & + .panel-content {
                            max-height: 99em;
                            overflow: inherit;
                            padding: 13px 15px;
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: 767px) {
      .sticky-search {
        .filter-container {
          padding:  0;
          .panel-title {
            .down-arrow {
              display: inline-block;
              vertical-align: middle;
            }
            & ~ .panel-content {
              display: none;
            }
            &.active {
              & ~ .panel-content {
                display: block;
              }
            }
          }
        }
      }
      #content {
        .section {
          display: inline-block;

          #search-filter {
            display: inline-block;
            vertical-align: middle;
            padding-right: 0;
            width: 100%;
            margin-bottom: 20px;
          }
          #search-result {
            display: inline-block;
            width: 100%;
            .content {
              ul {
                  justify-content: center;
                li {
                  &.mobile-ad {
                    & + li {
                      clear: both;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    @media all and (max-width: 525px) {
        .sticky-search {
            .filter-container {
                .panel-content {
                    fieldset {
                        width: 100% !important;
                        max-width: 100% !important;
                        margin: 0 auto 15px;
                        padding-left: 7px;
                    }
                }
            }
        }
    }

    @media all and (max-width: 479px) {
        .sticky-search {
            .filter-container {
                .panel-content {
                    fieldset {
                        .label {
                            margin-bottom: 5px;
                        }
                        &:first-child {
                            .label {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: 400px) {
        #content {
            .main-content {
                #search-result {
                    .content {
                        ul {
                            grid-template-columns: repeat(auto-fill, calc(50% - 15px));
                            column-gap: 15px;
                            row-gap: 15px;
                            li {
                                max-width: 160px;
                                .thumb {
                                    .crown {
                                        top: 88%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: 360px) {
        #content {
            .section {
                padding: 0 10px;
            }
        }
    }
</style>
<style lang="scss">
    .v-select {
      font-family: "Roboto", Helvetica, Arial, sans-serif;
      position: relative;
      .vs__selected-options {
          max-width: 100%;
      }

      .dropdown-toggle {
          background: #f8f8f8 !important;
          border: solid 1px rgba(0, 0, 0, .07) !important;
          border-radius: 3px !important;
          white-space: nowrap;
          height: 35px;
          color: #494949;
          cursor: pointer !important;
          position: relative;

          .selected-tag {
              padding: 8px 11px;
              width: calc(100% - 15px);
              height: auto;
              line-height: 18px;
              font-size: 15px;
              font-weight: 300;
              color: #494949;
              display: inline-block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              border: none;
              margin: 0;
              border-radius: 0;
          }

          .clear {
              display: none;
          }
      }

      input[type=search] {
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          position: absolute !important;
          left: 0;
          cursor: pointer;
          font-family: "Roboto", Helvetica, Arial, sans-serif;
          font-size: 15px !important;
          font-weight: 300 !important;
          line-height: 18px !important;
          color: #494949 !important;
          padding: 7px 32px 10px 13px !important;
          border: none;
          margin: 0 !important;
          width: 100% !important;
      }

      .dropdown-menu {
          position: absolute !important;
          top: 110% !important;
          margin-top: 0 !important;
          background: #fff !important;
          border-radius: 3px !important;
          border: 1px solid #71c5ff !important;
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .3) !important;
          padding: 0;
          max-height: 200px !important;

          li {
              font-size: 15px;
              line-height: 22px;
              font-weight: 300;
              padding: 0;
              display: inline-block;
              width: 100%;
              white-space: normal;
              word-wrap: break-word;

              a {
                  padding: 10px;
                  color: rgba(0, 0, 0, .7) !important;
                  white-space: normal;
                  word-wrap: break-word;

                  &:hover {
                      background: #e7f5fc !important;
                      color: rgba(0, 0, 0, .7);
                  }
              }

              &.no-options {
                  padding: 10px;
                  color: rgba(0, 0, 0, .7);
                  text-align: left;
              }
          }

          & > .highlight > a {
              background: #e7f5fc !important;
          }
      }

      .open-indicator {
          position: absolute;
          width: 7px;
          top: 12px;
          right: 10px;
          height: 7px;

          &::before {
              width: 7px !important;
              height: 7px !important;
              border-width: 2px 2px 0 0 !important;
              border-color: rgba(0, 0, 0, 0.2) !important;
          }
      }

      &.open {
          .open-indicator {
              top: 15px !important;
          }
      }
      &.disabled {
        .dropdown-toggle {
          background: #eee !important;
          * {
            cursor: default !important;
            user-select: none;
          }
          .selected-tag {
            color: #999;
          }
          input {
            background: transparent;
          }
          .open-indicator {
            background-color: transparent;
          }
        }
      }
    }

    .vue-slider-component {
        padding: 4px !important;

        .vue-slider {
            height: 4px !important;
        }

        .vue-slider-process {
            background: #00a3ff !important;
            border-radius: 4px;
        }

        .vue-slider-dot {
            width: 8px !important;
            height: 8px !important;
            top: -2px !important;
            background: #00a3ff !important;
            box-shadow: none !important;
        }

        &.vue-slider-horizontal {
            .vue-slider-dot {
                left: 4px;
            }
        }
    }
</style>
