<template>
    <div id="roomprofile-search">
        <div class="section clearfix">
            <div class="search-bar notranslate">
                <input type="text" placeholder="Search chat rooms e.g. music..."
                       v-model="keyword"
                       v-on:keyup.enter="search"
                       v-on:keyup="updateKeyword"
                       :class="{'active': keyword!== ''}">
                <a class="search-icon" v-on:click="search"></a>
            </div>
            <div class="online-counter" v-if="showCounter">
                <ul class="notranslate">
                    <li class="active" v-for="(item, index) in generateOpenRoomsCount" v-bind:key="index">{{item}}
                    </li>
                </ul>
                <span class="title">Chat Rooms <br>Open Now</span>
            </div>
            <div id="error-msg" class="error-msg search" v-show="errorMsg !== ''">{{errorMsg}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'searchBarGroups',
        props: {
            openRoomsCount: {
                type: String,
                required: true
            },
            keywordParent: {
                type: String,
                required: true
            },
            errorMsg: {
                type: String,
                required: true
            }
        },
        data: () => ({
            keyword: '',
            showCounter: false
        }),
        computed: {
            generateOpenRoomsCount() {
                return this.openRoomsCount.split('');
            },
        },
        methods: {
            search() {
                this.$emit('search', this.keyword);
            },
            updateKeyword() {
                this.$emit('updateKeyword', this.keyword);
            }
        },
        mounted() {
            if (this.keywordParent !== '') {
                this.keyword = this.keywordParent;
            }
        },
        watch: {
            'keywordParent': function () {
                this.keyword = this.keywordParent;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .search-icon {
        display: inline-block;
        vertical-align: middle;
        background-color: transparent;
        background-image: url('https://www.palassets.com/sprites/search_icon.png');
        background-repeat: no-repeat;
        background-position: 0 0;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    #roomprofile-search {
        background: #0095d5;
        min-height: 87px;
        padding: 33px 0 30px 0;
        .section {
            width: 100%;
            margin: 0 auto;
            padding: 0 32px;
            max-width: 1312px;
            box-sizing: border-box;
            text-align: center;
            .search-bar {
                background: #fff;
                width: 532px;
                max-width: 556px;
                height: 25px;
                border-radius: 4px;
                padding: 8px 12px;
                position: relative;
                display: inline-block;
                vertical-align: middle;
                text-align: left;
                input[type="text"] {
                    border: none;
                    border-radius: 0;
                    box-shadow: none;
                    background: none;
                    width: 500px;
                    padding: 0;
                    margin: 0;
                    height: 25px;
                    font-size: 17px;
                    font-style: italic;
                    color: #494949;
                    font-weight: 300;
                    font-family: "Roboto", Helvetica, Arial, sans-serif;
                    position: relative;
                    top: 0;
                    left: 0;
                    &::placeholder {
                        color: #e3e3e3;
                    }
                    &:-webkit-autofill {
                        -webkit-box-shadow: none;
                        -webkit-text-fill-color: unset;
                    }
                    &:-webkit-autofill:focus {
                        -webkit-box-shadow: none;
                        -webkit-text-fill-color: unset;
                        outline: none;
                        border: none;
                    }
                    &:focus {
                        outline: none;
                        color: #494949;
                    }
                    &.active {
                        color: #494949;
                    }
                    &.active_input {
                        background: none !important;
                    }
                }
                .search-icon {
                    position: absolute;
                    right: 12px;
                    top: 8px;
                }
            }
            .online-counter {
                display: inline-block;
                vertical-align: middle;
                margin-left: 15px;
                ul {
                    display: inline-block;
                    vertical-align: middle;
                    position: relative;
                    padding: 0 4px;
                    &::before, &::after {
                        content: '';
                        display: inline-block;
                        vertical-align: middle;
                        background-color: #0088c2;
                        border: solid 1px #0095d5;
                        width: 3px;
                        height: 4px;
                        position: absolute;
                        top: 50%;
                        margin: -2px 0 0 0;
                        z-index: 1;
                    }
                    &::before {
                        left: 1px;
                    }
                    &::after {
                        right: 1px;
                    }
                    li {
                        display: inline-block;
                        vertical-align: middle;
                        float: left;
                        margin-right: 1px;
                        background: #0082c8 url('https://www.palassets.com/sprites/onlinecounter-bg.png') no-repeat -14px 19px;
                        font-size: 27px;
                        font-weight: 400;
                        line-height: 41px;
                        color: #096A93;
                        width: 41px;
                        height: 41px;
                        text-align: center;
                        position: relative;
                        &:first-child {
                            border-radius: 4px 0 0 4px;
                        }
                        &:last-child {
                            border-radius: 0 4px 4px 0;
                            margin-right: 0;
                        }
                        &.active {
                            color: #bcd7e2;
                        }
                    }
                }
                .title {
                    margin-left: 3px;
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.2;
                    color: #BDD8E3;
                    text-transform: uppercase;
                    text-align: left;
                }
            }
            .error-msg {
              display: block;
              max-width: 556px;
              margin: 7px auto 0 auto;
              color: #f00;
              font-size: 13px;
              font-weight: 500;
              text-align: left;
              &.hide {
                  display: none;
              }
            }
        }
    }

    /*@media all and(max-width: 1024px) {
        #roomprofile-search {
            .section {
                .search-bar {
                    width: 55%;
                }
            }
        }
    }

    @media all and(max-width: 960px) {
        #roomprofile-search {
            .section {
                .search-bar {
                    width: 300px;
                    input[type="text"] {
                        width: 268px;
                    }
                }
            }
        }
    }*/

    @media all and(max-width: 767px) {
        #roomprofile-search {
            height: auto;
            padding-bottom: 20px;
            .section {
                padding: 0 30px;
                .search-bar {
                    /*margin-bottom: 13px;*/
                    width: 92.5%;
                    input[type="text"] {
                        width: auto;
                        min-width: 91%;
                    }
                }
                .online-counter {
                    margin: 0 auto;
                    ul {
                        margin-bottom: 10px;
                    }
                    .title {
                        font-size: 12px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    @media all and(max-width: 400px) {
        #roomprofile-search {
            .section {
                padding: 0 25px;
                .online-counter {
                    ul {
                        li {
                            width: 30px;
                            &.strike-through {
                                background-position: -33px -9px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
