<template>
    <li class="item" :class="[getSubscription, 'top-members-data']">
        <div class="thumb">
            <div class="image" v-lazy-container="{ selector: 'img' }" :style="{ 'background-color' : getRandomBG }">
                <a :href="`https://www.paltalk.com/g2/group/${group.encoded_id}/DisplayGroupDetails.wmt`"
                   target="_blank">
                    <img :data-src="groupImg"
                         :data-error="groupNoIconImg(group.category)"
                         :data-loading="loaderUrl"
                         :alt="group.group_name"
                         :class="{ 'category-icon' : group['image_name'] === '' }"
                    />
                </a>
            </div>
            <span class="subscription notranslate" :class="[getSubscription]" v-show="group['subscription'] !== ''">
                {{group['subscription']}}
            </span>
        </div>
        <div class="room-info">
            <h4 class="notranslate">
                <i class="icon-locked" v-show="group.isLocked === 'Y'"></i>
                <a :href="`https://www.paltalk.com/g2/group/${group.encoded_id}/DisplayGroupDetails.wmt`"
                   target="_blank">
                    {{group.group_name}}
                </a>
            </h4>
            <p class="notranslate">
                <router-link :to="`/groups/categories/${seoFriendlyUrl(group.category_display)}`" class="cat">
                    {{group.category_display}}
                </router-link>
                <router-link
                        :to="`/groups/categories/${seoFriendlyUrl(group.category_display)}/${seoFriendlyUrl(group.subcategory_display)}`"
                        class="subcat">
                    {{group.subcategory_display}}
                </router-link>
            </p>
            <div class="room-details notranslate">
                <ul>
                    <li><span class="language" :title="group.language_display">{{group.language_display}}</span></li>
                    <li><span :class="group.rating === 'G' ? 'G-rating' : 'R-rating'">{{group.rating}}</span></li>
                    <li v-show="group.followers_count > 0">
                        <i class="icon-followers" title="Followers"></i><span> {{group.followers_count}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </li>
</template>

<script>
    import Vue from 'vue';
    import VueLazyLoad from 'vue-lazyload';
    import slug from 'speakingurl';

    const backgroundColors = ["#B8D075", "#E2D573", "#D1A876", "#B26C49", "#CF9AC6", "#9E70B0", "#7696CD", "#456BA5",
        "#ACDBCF", "#6DBCAD", "#9ACEA3", "#64B770", "#F28888"];

    Vue.use(VueLazyLoad);

    export default {
        name: 'groupProfileCard',
        data: () => ({
            loaderUrl: 'https://www.palassets.com/sprites/loader/loader-white.gif',
        }),
        props: {
            group: {
                type: Object,
                required: true
            },
            imgPrefix: {
                type: String,
                required: false,
                default: 'https://groups.palassets.com/size160/'
            },
            noIcon: {
                type: Object,
                required: false,
                default: () => ({
                    baseUrl: 'https://www.palassets.com/groups/noImg/categoryIcons/',
                    ext: '.svg'
                })
            }
        },
        computed: {
            getRandomBG() {
                return backgroundColors[Math.floor(Math.random() * backgroundColors.length)];
            },
            getSubColor() {
                const subColor = (this.group.subColor) || '000000000';
                return {'color': `rgb(${subColor.substr(0, 3)}, ${subColor.substr(3, 3)}, ${subColor.substr(6, 3)})`};
            },
            getSubscription() {
                return (this.group['subscription'].toLowerCase()) || '';
            },
            groupImg() {
                if (this.group['image_name'] === '') {
                    return this.groupNoIconImg(this.group.category);
                }
                return `${this.imgPrefix}${this.group['image_name']}`;
            },
        },
        methods: {
            groupNoIconImg(categoryCode) {
                return `${this.noIcon.baseUrl}${categoryCode}${this.noIcon.ext}`;
            },
            seoFriendlyUrl(input) {
                return slug(input || '');
            }
        }
    }
</script>

<style lang="scss" scoped>
    li {
        display: inline-block;
        float: left;
        width: 160px;
        &.item {
            border-radius: 2px;
            position: relative;
            border: solid 1px transparent;
            &.deluxe {
                border-color: #31acff;
            }
            &.ultimate {
                border-color: #91ce00;
            }
            &.elite {
                border-color: #be4dde;
            }
        }
        .deluxe {
            background: #31acff;
        }
        .ultimate {
            background: #91ce00;
        }
        .elite {
            background: #be4dde;
        }
        .subscription {
            display: inline-block;
            font-size: 12px;
            font-weight: 500;
            color: #fff;
            line-height: 14px;
            padding: 6px 0 5px;
            text-align: center;
            text-transform: uppercase;
            width: calc(100% + 4px);
            margin-left: -2px;
            margin-right: -2px;
            position: absolute;
            z-index: 1;
            bottom: -5px;
            left: 0;
            &::before {
                content: '';
                display: inline-block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 4px 0 0 3px;
                position: absolute;
                right: 0;
                top: -4px;
            }
            &::after {
                content: '';
                display: inline-block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 0 4px 3px;
                position: absolute;
                left: 0;
                top: -4px;
            }
            &.deluxe {
                &::before {
                    border-color: transparent transparent transparent #0b629c;
                }
                &::after {
                    border-color: transparent transparent #0b629c transparent;
                }
            }
            &.ultimate {
                &::before {
                    border-color: transparent transparent transparent #557900;
                }
                &::after {
                    border-color: transparent transparent #557900 transparent;
                }
            }
            &.elite {
                &::before {
                    border-color: transparent transparent transparent #66127e;
                }
                &::after {
                    border-color: transparent transparent #66127e transparent;
                }
            }
        }
        .thumb {
            display: inline-block;
            width: 100%;
            height: 114px;
            position: relative;
            .image {
                display: inline-block;
                float: left;
                width: 100%;
                height: 114px;
                background-color: #cbe6f6;
                text-align: center;
                overflow: hidden;
                a {
                    img {
                        width: 100%;
                        height: auto;
                        max-width: 100%;
                        display: inline-block;
                        vertical-align: middle;
                        position: relative;
                        top: 50%;
                        transform: translateY(-50%);
                        &.category-icon {
                            max-width: 80%;
                            max-height: 80%;
                        }
                    }
                    img[lazy=loading] {
                        width: 35px;
                        height: 35px;
                    }
                    img[lazy=error] {
                        max-width: 75%;
                        max-height: 75%;
                    }
                    .adult-image {
                        -webkit-filter: blur(5px);
                        filter: blur(5px);
                    }
                }
            }
        }
        .room-info {
            padding: 10px;
            display: inline-block;
            vertical-align: middle;
            float: left;
            text-align: left;
            width: 137px;
            width: calc(100% - 20px);
            position: relative;
            background: #fff;
            .icon-locked {
                display: inline-block;
                vertical-align: middle;
                background: transparent url('https://www.palassets.com/discover2/images/sprite-discover.svg?v=3') no-repeat -200px -1px;
                width: 10px;
                height: 10px;
                position: relative;
                top: -2px;
            }
            h4 {
                font-size: 14px;
                line-height: 1.35;
                color: #4a4a4a;
                font-weight: 400;
                margin-bottom: 3px;
                max-width: 100%;
                height: 38px;
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: break-word;
                a {
                    color: #4a4a4a;
                }
            }
            p {
                font-size: 12px;
                line-height: 17px;
                color: #a8a8a8;
                font-weight: 400;
                margin-bottom: 10px;
                height: 34px;
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: break-word;
                a {
                    color: #858585;
                    &:first-child {
                        &::after {
                            content: '\00BB';
                            margin: 0 3px;
                        }
                    }
                }
            }
            .subscription-badge {
                text-align: center;
                margin-bottom: 5px;
            }
            .room-details {
                display: block;
                font-size: 11px;
                font-family: open sans;
                font-weight: 400;
                color: #929292;
                text-align: left;
                height: 15px;
                .icon-followers {
                    display: inline-block;
                    vertical-align: middle;
                    background: transparent url('https://www.palassets.com/discover2/images/sprite-discover.svg?v=3') no-repeat -167px -2px;
                    width: 10px;
                    height: 10px;
                    position: relative;
                    top: -1px;
                }
                ul {
                    border: none;
                    height: auto;
                    width: 100%;
                    li {
                        display: inline-block;
                        vertical-align: middle;
                        background: 0 0;
                        padding: 0;
                        border: none;
                        margin: 0 0 0 8px;
                        width: auto;
                        float: none;
                        &:first-child {
                            margin-left: 0;
                        }
                        .A-rating, .R-rating, .G-rating {
                            color: #fff;
                            padding: 1px 4px;
                            border-radius: 3px;
                            font-family: "Roboto", Helvetica, Arial, sans-serif;
                            font-size: 11.5px;
                            font-weight: 500;
                        }
                        .A-rating {
                            background: #ffc924;
                        }
                        .R-rating {
                            background: #00c97e;
                        }
                        .G-rating {
                            background: #ff745f;
                        }
                        .language {
                            display: inline-block;
                            overflow: hidden;
                            max-width: 65px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            line-height: 1.25;
                        }
                    }
                }
            }
        }
    }
</style>
